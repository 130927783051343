var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-2"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('router-link', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3",
    class: _vm.$colors.primary,
    attrs: {
      "to": "/zones"
    }
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_zone")) + " ")]) : _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("edit_zone")) + " ")])], 1)]), _c('div', {}, [_c('div', {
    staticClass: "bg-white rounded-lg p-6 pt-2"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "name"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.name,
      expression: "formData.name"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "name",
      "placeholder": _vm.$t('zone_name'),
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "country"
    }
  }, [_vm._v(_vm._s(_vm.$t("country")))])])]), _c('p', [_c('v-select', {
    attrs: {
      "options": _vm.warehouses,
      "label": "name"
    },
    on: {
      "input": _vm.setSelected
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(option) {
        return [_c('img', {
          staticClass: "flag-img",
          attrs: {
            "src": _vm.$f.getCountryCode(option.code)
          }
        }), _vm._v(" " + _vm._s(option.name) + " ")];
      }
    }]),
    model: {
      value: _vm.formData.country,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "country", $$v);
      },
      expression: "formData.country"
    }
  })], 1)])]), _c('div', [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "flex w-full space-x-6 h-full overflow-hidden"
  }, [_c('div', {
    staticClass: "border mt-8 w-1/2 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "cities"
    }
  }, [_vm._v(_vm._s(_vm.$t("add_cities"))), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v(" *")])])])]), _c('div', {
    staticClass: "md:w-full overflow-y-auto h-full flex flex-col items-center p-1",
    attrs: {
      "id": "city"
    }
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "flex flex-col items-center relative"
  }, [_c('div', {
    staticClass: "w-full"
  }, [_c('div', {
    staticClass: "mt-2 mb-4 p-1 bg-white flex border border-gray-200 rounded"
  }, [_c('div', {
    staticClass: "flex flex-auto flex-wrap"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.citiesInput,
      expression: "citiesInput"
    }],
    staticClass: "p-1 px-2 appearance-none outline-none w-full text-gray-800",
    attrs: {
      "placeholder": _vm.$t('enter_cities')
    },
    domProps: {
      "value": _vm.citiesInput
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.trigger.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.citiesInput = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
  }, [_c('button', {
    ref: "sendReply",
    staticClass: "cursor-pointer w-6 h-6 text-green-500 outline-none focus:outline-none",
    on: {
      "click": _vm.addCities
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("add")])])])])]), _vm.formData.cities.length > 0 ? _c('div', {
    staticClass: "tag_cities"
  }, _vm._l(_vm.formData.cities, function (city, index) {
    return _c('span', {
      key: index,
      staticClass: "tag label label-info"
    }, [_c('span', {
      staticClass: "text-chip vs-chip--text"
    }, [_vm._v(_vm._s(city))]), _c('span', {
      staticClass: "btn-close vs-chip--close",
      attrs: {
        "data-role": "remove"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteCity(index);
        }
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "py-2"
  }, [_c('span', {
    staticClass: "w-full flex justify-center text-center border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-green-500 whitespace-no-wrap"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("no_cities_added")))])])])])])])])]), _c('div', {
    staticClass: "mt-8 w-1/2"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "price"
    }
  }, [_vm._v(_vm._s(_vm.$t("price")))])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.price,
      expression: "formData.price"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "price",
      "min": "0",
      "type": "number"
    },
    domProps: {
      "value": _vm.formData.price
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "price", $event.target.value);
      }
    }
  })])])])])]), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }