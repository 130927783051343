<template>
  <div class="flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-2">
        <div class="flex">
          <router-link
            :class="$colors.primary"
            class="rounded-full flex justify-center items-center w-8 h-8 mt-1 mr-3"
            to="/zones"
          >
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
            {{ $t("new_zone") }}
          </div>
          <div v-else class="text-2xl font-bold text-green-500">
            {{ $t("edit_zone") }}
          </div>
        </div>
      </div>
      <div class="">
        <div class="bg-white rounded-lg p-6 pt-2">
          <div class="grid lg:grid-cols-2 gap-6">
            <div
              class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1">
                    {{ $t("name") }}
                    <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <p>
                <input
                  id="name"
                  :placeholder="$t('zone_name')"
                  tabindex="0"
                  type="text"
                  v-model="formData.name"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </p>
            </div>
            <div
              class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="country" class="bg-white text-gray-700 px-1">{{
                    $t("country")
                  }}</label>
                </p>
              </div>
              <p>
                <v-select
                  @input="setSelected"
                  v-model="formData.country"
                  :options="warehouses"
                  label="name"
                >
                  <template slot="option" slot-scope="option">
                    <img
                      class="flag-img"
                      :src="$f.getCountryCode(option.code)"
                    />
                    {{ option.name }}
                  </template>
                </v-select>
              </p>
            </div>
          </div>
          <div>
            <!-- ************************************************************************************ -->
            <!-- ************************************Add cities***********************************-->
            <div class="w-full">
              <div class="flex w-full space-x-6 h-full overflow-hidden">
                <div
                  class="border mt-8 w-1/2 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                >
                  <div
                    class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                  >
                    <p>
                      <label for="cities" class="bg-white text-gray-700 px-1"
                        >{{ $t("add_cities")
                        }}<span :class="$colors.required"> *</span></label
                      >
                    </p>
                  </div>
                  <div
                    id="city"
                    class="md:w-full overflow-y-auto h-full flex flex-col items-center p-1"
                  >
                    <div class="w-full">
                      <div class="flex flex-col items-center relative">
                        <div class="w-full">
                          <div
                            class="mt-2 mb-4 p-1 bg-white flex border border-gray-200 rounded"
                          >
                            <div class="flex flex-auto flex-wrap"></div>
                            <input
                              v-model="citiesInput"
                              @keyup.enter="trigger"
                              :placeholder="$t('enter_cities')"
                              class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                            />
                            <div
                              class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200"
                            >
                              <button
                                @click="addCities"
                                ref="sendReply"
                                class="cursor-pointer w-6 h-6 text-green-500 outline-none focus:outline-none"
                              >
                                <i class="material-icons">add</i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <!-- Displaying cities -->
                        <div
                          v-if="formData.cities.length > 0"
                          class="tag_cities"
                        >
                          <span
                            v-for="(city, index) in formData.cities"
                            :key="index"
                            class="tag label label-info"
                          >
                            <span class="text-chip vs-chip--text">{{
                              city
                            }}</span>
                            <span
                              class="btn-close vs-chip--close"
                              @click="deleteCity(index)"
                              data-role="remove"
                            ></span>
                          </span>
                        </div>
                        <div v-else class="py-2">
                          <span
                            class="w-full flex justify-center text-center border-gray-200 text-base"
                            ><p
                              class="font-semibold flex text-green-500 whitespace-no-wrap"
                            >
                              <span>{{ $t("no_cities_added") }}</span>
                            </p></span
                          >
                        </div>
                        <!-- / Displaying cities -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-8 w-1/2">
                  <div
                    class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
                  >
                    <div
                      class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
                    >
                      <p>
                        <label
                          for="price"
                          class="bg-white text-gray-700 px-1"
                          >{{ $t("price") }}</label
                        >
                      </p>
                    </div>
                    <p>
                      <input
                        id="price"
                        min="0"
                        type="number"
                        v-model="formData.price"
                        class="py-2 text-xs px-2 outline-none block h-full w-full"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 pt-3 flex justify-center">
              <button
                @click="save"
                class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
              >
                {{ $t("save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        country: "",
        cities: [],
        price: 0,
      },
      citiesInput: "",
      warehouses: [],
      options: this.$countries,
      selectContry: "",
    };
  },
  async mounted() {
    await this.getUser();
    this.dataToEdit();
    await this.getWarehouses();
  },
  methods: {
    async getWarehouses() {
      const filter = { limit: 1000 };
      const res = await this.$server.search("warehouses", filter);
      if (res.content.results) {
        let data = res.content.results;
        for (let i in data) {
          this.warehouses.push({
            name: this.$f.getCountryNameByCode(this.options, data[i].country),
            code: data[i].country,
          });
        }
      } else this.warehouses = [];
    },
    setSelected(contry) {
      if (contry) {
        this.formData.country = contry.name;
        this.selectContry = contry.code;
      }
    },
    async save() {
      if (this.formData._id) {
        this.formData.country = this.selectContry;
        if (this.formData.cities.length > 0) {
          if (this.checkData()) {
            const data = await this.$server.update("zones", this.formData);
            if (data._id) {
              this.formData.country = this.$f.getCountryNameByCode(
                this.options,
                this.selectContry
              );
              alert(this.$t("zone_updated"), "success");
            }
          } else {
            alert(this.$t("all_required_field"), "warning");
          }
        } else {
          alert(this.$t("cities_required"), "warning");
        }
      } else {
        if (this.formData.cities.length > 0) {
          if (this.checkData()) {
            this.formData.country = this.selectContry;
            const data = await this.$server.create("zones", this.formData);
            if (data._id) {
              alert(this.$t("zone_created"), "success");
              this.formData.cities = [];
              this.formData.country = this.selectContry = "";
            }
          } else {
            alert(this.$t("all_required_field"), "warning");
          }
        } else {
          alert(this.$t("cities_required"), "warning");
        }
      }
    },
    checkData() {
      if (
        this.formData.name &&
        this.formData.cities &&
        this.formData.price &&
        this.formData.country
      ) {
        return true;
      } else {
        return false;
      }
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
    },
    async dataToEdit() {
      if (this.$route.params.id) {
        const editData = await this.$server.get("zones", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        this.selectContry = this.formData.country;
        this.formData.country = this.$f.getCountryNameByCode(
          this.options,
          this.formData.country
        );
      }
    },
    /*************************************************************** */
    trigger() {
      this.$refs.sendReply.click();
    },
    addCities(index) {
      if (
        this.citiesInput === "" ||
        this.citiesInput === null ||
        this.citiesInput.value === 0
      ) {
        alert(this.$t("please_enter_city"), "warning");
      } else {
        this.formData.cities.push(this.citiesInput);
        // clear the input
        this.citiesInput = "";
      }
    },
    deleteCity(index) {
      this.formData.cities.splice(index, 1);
    },
    /**************************************************************/
  },
};
</script>

<style>
.tag_cities {
  width: 100%;
  max-width: 100%;
}
.vs__dropdown-toggle {
  border: none;
}
.tag_cities .tag {
  background: rgb(49 130 206 / 16%);
  border-radius: 20px;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 28px;
  padding-right: 10px;
  color: rgba(0, 0, 0, 0.7);
  position: relative;
  padding-right: 0;
}
.vs-chip--text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 10px;
}
.vs-chip--close {
  width: 20px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 0;
  margin: 0 4px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.tag_cities .tag [data-role="remove"]::after {
  content: "x";
  padding: 0px 2px;
  margin-bottom: 3px;
  font-size: 15px;
}
.tag_cities .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}
.top-100 {
  top: 100%;
}

.bottom-100 {
  bottom: 100%;
}

.max-h-select {
  max-height: 300px;
}
</style>
